import type { Clans } from 'lvl-io-sdk'
import { createItemStore } from '~/utils/pinia/light-item-store'
import { useClansMeStore } from '~/stores/clans/me'

type ClanWithProfile = Clans.Clan & Pick<Clans.ClanExpanded, 'profile'>

type ClanExpanded = ClanWithProfile &
  Pick<Clans.ClanExpanded, 'stat' | 'rating'>

export const useClansClanStore = createItemStore(
  'clans-clan',
  {
    getItemRequest: async () => {
      const { $sdk } = useNuxtApp()
      const { getClanByTag } = await $sdk.module('clans')

      return (
        ...[tag, requestQuery, pathOptions, requestOptions]: Parameters<
          typeof getClanByTag<ClanExpanded>
        >
      ) =>
        getClanByTag<ClanExpanded>(
          tag,
          {
            expand: ['profile', 'stat', 'rating'],
            ...(requestQuery ?? {})
          },
          pathOptions,
          requestOptions
        )
    }
  },
  context => {
    const { $sdk } = useNuxtApp()

    const create = async (body: Clans.CreateClanBody) => {
      const data = await $sdk
        .module('clans')
        .then(({ createClan }) => createClan({ body }))

      const clanMeStore = useClansMeStore()
      await clanMeStore.fetchData()

      return data
    }

    const update = async (body: Clans.UpdateClanBody) => {
      // TODO: нужно обновить clans/me если меняется tag
      const data = await $sdk.module('clans').then(({ updateClan }) =>
        updateClan<ClanWithProfile>({
          updates: body,
          requestQuery: {
            expand: ['profile']
          }
        })
      )

      context.updateData(data)

      return data
    }

    const uploadAvatar = async (file: File) => {
      const formData = new FormData()
      formData.append('file', file)

      const data = await $sdk
        .module('clans')
        .then(({ uploadAvatar }) => uploadAvatar({ body: formData }))

      context.updateData({ avatar: data })

      return data
    }

    const updateMessage = (message: string) =>
      $sdk
        .module('clans')
        .then(({ updateStaff }) => updateStaff({ updates: { message } }))

    const setMessage = (message?: string | null) => {
      const profile = Object.assign(context.data.value?.profile ?? {}, {
        message
      })

      context.updateData({ profile })
    }

    const fetchMessage = async (tag: ClanExpanded['tag']) => {
      type Data = Pick<ClanWithProfile, 'tag'> & {
        profile: Pick<ClanWithProfile['profile'], 'message'>
      }

      const data = await $sdk.module('clans').then(({ getClanByTag }) =>
        getClanByTag<Data>(tag, {
          expand: ['profile'],
          fields: ['tag', 'profile.message']
        })
      )

      setMessage(data.profile.message)
    }

    return {
      create,
      update,
      uploadAvatar,
      updateMessage,
      setMessage,
      fetchMessage
    }
  }
)
