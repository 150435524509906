<template>
  <nav :class="$style.nav">
    <ui-link
      v-for="link in nav"
      :key="link.title"
      :class="$style.btn"
      :to="link.to"
    >
      <span :class="$style.t">{{ link.title }}</span>
    </ui-link>
  </nav>
</template>

<i18n lang="yaml">
ru:
  main: Главная
  rating: Рейтинг
  members: Участники
  settings: Настройки
en:
  main: Main
  rating: Rating
  members: Members
  settings: Settings
</i18n>

<script setup>
import UiLink from '@/components/ui/link/index.vue'
import { useClansMeStore } from '~/stores/clans/me'

const { t } = useI18n()

const $props = defineProps({
  tag: {
    type: String,
    default: ''
  }
})

const clansMeStore = useClansMeStore()

const nav = [
  {
    to: `/clans/${$props.tag}`,
    title: t('main')
  },
  {
    to: '/clans/members',
    title: t('members')
  },
  {
    to: '/clans/rating',
    title: t('rating')
  }
]

if (clansMeStore.permission('settings')) {
  nav.splice(2, 0, {
    to: '/clans/settings',
    title: t('settings')
  })
}
</script>

<style lang="scss" module>
.nav {
  display: flex;
  gap: 0.4em;
  overflow: auto;

  .btn {
    background-color: #0a0e29;
    padding: 0.8em 1.2em;
    color: #acb0d5;
    border-radius: 0.4em;
    display: flex;
    align-items: center;
    transition: 0.3s background-color;

    &:hover {
      background-color: darken(#0a0e29, 5%);
    }

    &:global(.router-link-exact-active) {
      background-color: var(--app-active-color);
      color: #0a0e29;
    }

    .t {
      font-size: 1.4em;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      letter-spacing: -0.028em;
    }
  }
}
</style>
